/*
 * Main Script File
 * Author: Mario Jahn
 */

import ScrollPadlock from "/node_modules/scroll-padlock/dist/es/scroll-padlock.min.js";
const scrollPadlock = new ScrollPadlock();

/*#######PHOTOSWIPE LIGHTBOX / SHOP #######*/
import PhotoSwipeLightbox from "/node_modules/photoswipe/dist/photoswipe-lightbox.esm.min.js";
import PhotoSwipe from "/node_modules/photoswipe/dist/photoswipe.esm.min.js";

const lightbox = new PhotoSwipeLightbox({
	arrowPrevSVG:
		'<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 87.4 30.8" viewBox="0 0 87.4 30.8"><path d="M0 14.8V16c8.2 0 14.8 6.6 14.8 14.8H16c0-6.7-4.1-12.4-10-14.8h81.4v-1.2H6C11.9 12.4 16 6.7 16 0h-1.2c0 8.2-6.6 14.8-14.8 14.8z"/></svg>',
	arrowNextSVG:
		'<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 87.4 30.8" viewBox="0 0 87.4 30.8"><path d="M72.6 0h-1.2c0 6.7 4.1 12.4 10 14.8H0V16h81.4c-5.9 2.4-10 8.1-10 14.8h1.2c0-8.2 6.6-14.8 14.8-14.8v-1.2c-8.2 0-14.8-6.6-14.8-14.8z"/></svg>',
	closeSVG:
		'<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Ebene_1" x="0" y="0" style="enable-background:new 0 0 47.4 33.8" version="1.1" viewBox="0 0 47.4 33.8"><style>.st0{fill:none;stroke:#000;stroke-miterlimit:10}</style><path d="M40.1 33.3c-9.1 0-16.4-7.3-16.4-16.4S31 .5 40.1.5M7.3 33.3c9.1 0 16.4-7.3 16.4-16.4S16.4.5 7.3.5" class="st0"/></svg>',

	// to apply styles just to this instance of PhotoSwipe
	mainClass: "pswp--custom-bg",
	zoom: false,

	gallery: ".shop__gallery",
	children: ".pswp-gallery__item",
	pswpModule: PhotoSwipe,

	bgOpacity: 1,

	padding: { top: 100, bottom: 100, left: 50, right: 50 },
});

lightbox.on("uiRegister", function () {
	lightbox.pswp.ui.registerElement({
		name: "custom-caption",
		order: 9,
		isButton: false,
		appendTo: "root",
		html: "Caption text",
		onInit: (el, pswp) => {
			lightbox.pswp.on("change", () => {
				const currSlideElement = lightbox.pswp.currSlide.data.element;
				let captionHTML = "";
				if (currSlideElement) {
					const hiddenCaption = currSlideElement.querySelector(
						".hidden-caption-content"
					);
					if (hiddenCaption) {
						// get caption from element with class hidden-caption-content
						captionHTML = hiddenCaption.innerHTML;
					} else {
						// get caption from alt attribute
						captionHTML = currSlideElement
							.querySelector("img")
							.getAttribute("alt");
					}
				}
				el.innerHTML = captionHTML || "";
			});
		},
	});
});
if (document.querySelectorAll(".shop__gallery")) {
	lightbox.init();
}

document.addEventListener("facetwp-loaded", function () {
	lightbox.init();
});

/*#######THEME SWITCH / SESSION STORAGE #######*/
//Handles theme switch and session storage.
//Adapted from https://fossheim.io/writing/posts/accessible-theme-picker-html-css-js/

// Selectors and default theme
const pressedButtonSelector = '[data-theme][aria-pressed="true"]';
const defaultTheme = "temperate";

// Button selectors
const themeSwitcher = document.querySelector(".theme-switcher");
const buttons = themeSwitcher.querySelectorAll("button");

// Flag to check if audio is playing
let isPlaying = false;

/**
 * Applies selected theme to the page.
 *
 * @param {string} theme - The name of the selected theme.
 */
const applyTheme = (theme) => {
	// Get all elements from theme switcher
	const targets = document.querySelectorAll(`[data-theme="${theme}"]`);

	// Assign selected theme to HTML
	document.documentElement.setAttribute("data-selected-theme", theme);

	if (theme.startsWith("home-")) {
		// Assign selected theme to audio
		var audioSource =
			stylesheet_directory_uri +
			"/assets/sounds/" +
			theme.replace("home-", "") +
			".mp3";
	} else {
		// Assign selected theme to audio
		var audioSource =
			stylesheet_directory_uri + "/assets/sounds/" + theme + ".mp3";
	}

	document
		.getElementById("sound")
		.querySelector("source")
		.setAttribute("src", audioSource);
	document.getElementById("sound").load();

	// Assign home theme to HTML
	if (localStorage.getItem("home-theme")) {
		document.documentElement.setAttribute(
			"data-home-theme",
			localStorage.getItem("home-theme")
		);

		// Assign home theme switch the home theme
		document
			.getElementById("theme-switcher__item_home")
			.setAttribute("data-theme", localStorage.getItem("home-theme"));
	}

	// Change aria attribute to true after button press
	document
		.querySelector(pressedButtonSelector)
		.setAttribute("aria-pressed", "false");

	if (targets) {
		targets.forEach((target) => {
			target.setAttribute("aria-pressed", "true");
		});
	}

	// If home theme is same as selected theme make home theme selected and hide duplicate theme
	/*if (targets) {
		const len = targets.length;
		targets.forEach((target) => {
			if (len > 1) {
				if (target.hasAttribute("id")) {
					target.setAttribute("aria-pressed", "true");
				}
			} else {
				target.setAttribute("aria-pressed", "true");
			}
		});
	}*/

	// Check if geolocation is set
	if (localStorage.getItem("no-geolocation") === "no-location") {
		document.getElementById("home--no_location").classList.remove("hidden");
		document.getElementById("home--location").classList.add("hidden");
	} else {
		document.getElementById("home--no_location").classList.add("hidden");
		document.getElementById("home--location").classList.remove("hidden");
	}
};

/**
 * Handles theme selection.
 *
 * @param {Event} event - The event object.
 */
const handleThemeSelection = (event) => {
	const target = event.target;
	const isPressed = target.getAttribute("aria-pressed");

	/* If clicked theme is different from current theme */
	if (isPressed !== "true") {
		const theme = target.getAttribute("data-theme");
		applyTheme(theme);
		localStorage.setItem("selected-theme", theme);

		// Stop audio when theme changes
		const soundElement = document.getElementById("sound");
		soundElement.pause();
		soundElement.load();
		soundElement.play();
		isPlaying = true;
		document.getElementById("btn-sound").classList.add("isPlaying");

		// Set coordinates
		// If home theme get home coordinates …
		if (theme.startsWith("home-")) {
			localStorage.setItem("longitude", localStorage.getItem("longitude-home"));
			localStorage.setItem("latitude", localStorage.getItem("latitude-home"));

			const parentLat = document.getElementById("change_mood_modal__latitude");
			const childLat = parentLat.querySelector(`.${"home"}`);
			childLat.innerHTML = localStorage.getItem("latitude");

			const parentLong = document.getElementById(
				"change_mood_modal__longitude"
			);
			const childLong = parentLong.querySelector(`.${"home"}`);
			childLong.innerHTML = localStorage.getItem("longitude");
		} else {
			// … else get specific theme coordinates
			var themeLocation = getFromLocalStorage("name", theme);
			localStorage.setItem("longitude", themeLocation[0]["longitude"]);
			localStorage.setItem("latitude", themeLocation[0]["latitude"]);
		}
		// Update HTML elements with theme coordinates
		const positionLatNodes = document.getElementsByClassName("positionLat");
		for (let i = 0; i < positionLatNodes.length; i++) {
			positionLatNodes[i].innerHTML = localStorage.getItem("latitude");
		}
		const positionLongNodes = document.getElementsByClassName("positionLong");
		for (let i = 0; i < positionLongNodes.length; i++) {
			positionLongNodes[i].innerHTML = localStorage.getItem("longitude");
		}
	}
};

/**
 * Get value from local storage where a key has a specific value
 * @param {string} key - The key to search for
 * @param {string} value - The value to match against the key
 * @returns {any} - The value of the first matching key-value pair found in the local storage array
 */
function getFromLocalStorage(key, value) {
	try {
		// Get the array from local storage
		const array = JSON.parse(localStorage.getItem("themeData"));

		// Find the first matching key-value pair
		const result = array.filter((item) => item[key] === value);

		// Return the value of the matching key-value pair
		return result;
	} catch (error) {
		// Log the error and return null
		console.error(error);
		return [];
	}
}

/**
 * Sets initial theme based on saved theme in local storage.
 * If no saved theme is found, shows user's position to determine default theme.
 * If saved theme is different from default theme, applies saved theme.
 * If user's position is saved in local storage, fills all position placeholders with coordinates.
 */
const setInitialTheme = () => {
	const savedTheme = localStorage.getItem("selected-theme");
	const positionLat = localStorage.getItem("latitude");
	const positionLong = localStorage.getItem("longitude");

	// If no saved theme is found, show user's position to determine default theme
	if (!savedTheme) {
		showPosition();
	}
	// If saved theme is different from default theme, apply saved theme
	else if (savedTheme && savedTheme !== defaultTheme) {
		applyTheme(savedTheme);
	}

	// If user's position is saved in local storage, fill all position placeholders with coordinates
	if (positionLat && positionLong) {
		const positionLatNodes = document.getElementsByClassName("positionLat");
		for (let i = 0; i < positionLatNodes.length; i++) {
			positionLatNodes[i].innerHTML = positionLat;
		}
		const positionLongNodes = document.getElementsByClassName("positionLong");
		for (let i = 0; i < positionLongNodes.length; i++) {
			positionLongNodes[i].innerHTML = positionLong;
		}
	}

	/*
	Replaced via global .positionLat DIV - also working in aside.php
	// Check if geolocation is set
	if (localStorage.getItem("no-geolocation") === "no-location") {
		document.getElementById("home--no_location").classList.add("hidden");
		document.getElementById("home--location").classList.remove("hidden");
		//Add 00,00,00 placeholder coordinates
		document.getElementById("home__no-latitude").classList.remove("hidden");
		document.getElementById("home__no-longitude").classList.remove("hidden");
	} else {
		document.getElementById("home--no_location").classList.remove("hidden");
		document.getElementById("home--location").classList.add("hidden");
		//Remove 00,00,00 placeholder coordinates
		document.getElementById("home__no-latitude").classList.add("hidden");
		document.getElementById("home__no-longitude").classList.add("hidden");
	}
	*/
};

// Selects all buttons
// Adds the handleThemeSelection as a click handler to each of the buttons
buttons.forEach((button) => {
	button.addEventListener("click", handleThemeSelection);
});

/*
// Trigger Geolocation
const buttonGetLocation = document.querySelector('#trigger_geolocation');
buttonGetLocation.addEventListener('click', setInitialTheme);*/

setInitialTheme();

/*####### GET GEOLOCATION #######*/
// Get saved theme and home theme from local storage
const savedTheme = localStorage.getItem("selected-theme");
const homeTheme = localStorage.getItem("home-theme");

// Set up logging
const logger = {
	info: (message) => console.log(`INFO: ${message}`),
	error: (message) => console.log(`ERROR: ${message}`),
};

/**
 * Gets the user's geolocation and converts it to degrees, minutes, and seconds (DMS) format.
 */
function showPosition() {
	// Check if geolocation is supported by browser
	if (navigator.geolocation) {
		// Get current position
		navigator.geolocation.getCurrentPosition(
			successGeolocation,
			errorGeolocation
		);
	} else {
		// Geolocation not supported by browser
		logger.error("Sorry, your browser does not support HTML5 geolocation.");
		alert("Sorry, your browser does not support HTML5 geolocation.");
	}

	/**
	 * Converts decimal degrees to degrees, minutes, and seconds (DMS) format.
	 * @param {number} dd - Decimal degrees to be converted.
	 * @param {boolean} isLng - Whether the decimal degrees represent longitude or latitude.
	 */
	function convertToDms(dd, isLng) {
		// Determine direction based on sign of decimal degrees
		const dir = dd < 0 ? (isLng ? "W" : "S") : isLng ? "E" : "N";

		// Get absolute value of decimal degrees
		const absDd = Math.abs(dd);

		// Get degrees, minutes, and seconds
		const deg = absDd | 0;
		const frac = absDd - deg;
		const min = (frac * 60) | 0;
		let sec = frac * 3600 - min * 60;

		// Round seconds to 0 decimal points
		sec = Math.round(sec);

		// Construct DMS string and store in local storage
		if (isLng) {
			const longitude = `${deg}°${min}'${sec}"${dir}`;
			localStorage.setItem("longitude", longitude);
			localStorage.setItem("longitude-home", longitude);
		} else {
			const latitude = `${deg}°${min}'${sec}"${dir}`;
			localStorage.setItem("latitude", latitude);
			localStorage.setItem("latitude-home", latitude);
		}
	}

	/**
	 * Callback function for successful geolocation.
	 * @param {Object} position - Object containing latitude and longitude coordinates.
	 */
	function successGeolocation(position) {
		const positionLat = position.coords.latitude;
		const positionLong = position.coords.longitude;

		// Convert latitude and longitude to DMS format and store in local storage
		convertToDms(positionLat, false);
		convertToDms(positionLong, true);

		// Log generated DMS coordinates
		logger.info(
			`Latitude: ${localStorage.getItem(
				"latitude"
			)}, Longitude: ${localStorage.getItem("longitude")}`
		);

		// Update HTML elements with DMS coordinates
		const positionLatNodes = document.getElementsByClassName("positionLat");
		for (let i = 0; i < positionLatNodes.length; i++) {
			positionLatNodes[i].innerHTML = localStorage.getItem("latitude");
		}

		const positionLongNodes = document.getElementsByClassName("positionLong");
		for (let i = 0; i < positionLongNodes.length; i++) {
			positionLongNodes[i].innerHTML = localStorage.getItem("longitude");
		}

		/**
		 * Checks if a value is within a specified range.
		 * @param {number} x - Value to check.
		 * @param {number} min - Minimum value of range.
		 * @param {number} max - Maximum value of range.
		 * @returns {boolean} True if value is within range, false otherwise.
		 */
		function inRange(x, min, max) {
			return (x - min) * (x - max) <= 0;
		}

		// Set theme based on climate zone if saved theme is not set
		if (!savedTheme) {
			if (inRange(positionLat, 0, 23.5)) {
				applyTheme("tropical");
				localStorage.setItem("selected-theme", "tropical");
			} else if (inRange(positionLat, 23.5, 40)) {
				applyTheme("subtropical");
				localStorage.setItem("selected-theme", "subtropical");
			} else if (inRange(positionLat, 40, 60)) {
				applyTheme("temperate");
				localStorage.setItem("selected-theme", "temperate");
			} else if (inRange(positionLat, 60, 90)) {
				applyTheme("coldzone");
				localStorage.setItem("selected-theme", "coldzone");
			} else {
				applyTheme("home-temperate");
				localStorage.setItem("selected-theme", "home-temperate");
			}
		}

		// Set home theme if not set
		if (!homeTheme) {
			if (inRange(positionLat, 0, 23.5)) {
				localStorage.setItem("home-theme", "home-tropical");
			} else if (inRange(positionLat, 23.5, 40)) {
				localStorage.setItem("home-theme", "home-subtropical");
			} else if (inRange(positionLat, 40, 60)) {
				localStorage.setItem("home-theme", "home-temperate");
			} else if (inRange(positionLat, 60, 90)) {
				localStorage.setItem("home-theme", "home-coldzone");
			} else {
				localStorage.setItem("home-theme", "home-temperate");
			}

			// Show home button only if home theme/geolocation is active
			const homeButton = document.getElementById("theme-switcher__item_home");
			homeButton.setAttribute("data-theme", localStorage.getItem("home-theme"));
		}

		// Assign home theme name to HTML
		if (localStorage.getItem("home-theme")) {
			const homeTheme = localStorage.getItem("home-theme");
			document.documentElement.setAttribute(
				"data-home-theme",
				localStorage.getItem("home-theme")
			);

			// Show home button only if home theme/geolocation is active
			const homeButton = document.getElementById("theme-switcher__item_home");
			homeButton.setAttribute("data-theme", localStorage.getItem("home-theme"));
		}

		// Disable geolocation error message
		localStorage.setItem("no-geolocation", "location");
	}

	/**
	 * Callback function for failed geolocation.
	 * @param {Object} error - Object containing error message.
	 */
	function errorGeolocation(error) {
		logger.error(`Geolocation error: ${error.message}`);

		// Set default coordinates and theme if geolocation fails
		localStorage.setItem("longitude", "00°00'00\"E");
		localStorage.setItem("longitude-home", "00°00'00\"E");
		localStorage.setItem("latitude", "00°00'00\"N");
		localStorage.setItem("latitude-home", "00°00'00\"N");
		localStorage.setItem("home-theme", "home-temperate");

		// Activate geolocation error message
		localStorage.setItem("no-geolocation", "no-location");

		// Update HTML elements with default coordinates
		const positionLatNodes = document.getElementsByClassName("positionLat");
		for (let i = 0; i < positionLatNodes.length; i++) {
			positionLatNodes[i].innerHTML = localStorage.getItem("latitude");
		}

		const positionLongNodes = document.getElementsByClassName("positionLong");
		for (let i = 0; i < positionLongNodes.length; i++) {
			positionLongNodes[i].innerHTML = localStorage.getItem("longitude");
		}
	}
}

/*####### PLAY AUDIO VIA BUTTON #######*/
let audioEl = document.getElementById("sound");

//Set cookie that saves play status
function setCookie(c_name, value, exdays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + exdays);
	var c_value =
		escape(value) + (exdays == null ? "" : "; expires=" + exdate.toUTCString());
	document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
	var i,
		x,
		y,
		ARRcookies = document.cookie.split(";");
	for (i = 0; i < ARRcookies.length; i++) {
		x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
		y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
		x = x.replace(/^\s+|\s+$/g, "");
		if (x == c_name) {
			return unescape(y);
		}
	}
}

var played = false;
var tillPlayed = getCookie("timePlayed");

function update() {
	if (!played) {
		if (tillPlayed) {
			audioEl.currentTime = tillPlayed;
			//audioEl.play();
			played = true;
		} else {
			//audioEl.play();
			played = true;
		}
	} else {
		setCookie("timePlayed", audioEl.currentTime);
	}
}
setInterval(update, 250);

// Play audio on page load if it was previously playing
setTimeout(function () {
	if (localStorage.getItem("audio-state") == "playing") {
		audioEl.currentTime = tillPlayed;
		var promise = audioEl.play();
		if (promise !== undefined) {
			promise
				.catch((error) => {
					//alert("no autoplay :(");
					document.addEventListener(
						"touchstart",
						function () {
							audioEl.play();
							isPlaying = true;
							document.getElementById("btn-sound").classList.add("isPlaying");
						},
						{ once: true }
					);
				})
				.then(() => {
					isPlaying = true;
					document.getElementById("btn-sound").classList.add("isPlaying");
				});
		}
	}
}, 250);

document.getElementById("btn-sound").addEventListener("click", function () {
	isPlaying ? audioEl.pause() : audioEl.play();
});

audioEl.onplaying = function () {
	isPlaying = true;
	document.getElementById("btn-sound").classList.add("isPlaying");
	localStorage.setItem("audio-state", "playing");
};
audioEl.onpause = function () {
	isPlaying = false;
	document.getElementById("btn-sound").classList.remove("isPlaying");
	localStorage.setItem("audio-state", "muted");
};

jQuery(document).ready(function ($) {
	/*####### PRELOADER #######*/
	$("#container").imagesLoaded({ background: true }, function () {
		//$('#preloader__logo').addClass('slideDown');
		$("#preloader").fadeOut("450", function () {
			$(this).remove();
		});
	});

	/*####### MENU #######*/
	$(function () {
		$(".main-menu__nav").smartmenus(/*{ hideOnClick: true }*/);
		var $mainMenuState = $("#main-menu__state");
		if ($mainMenuState.length) {
			// animate mobile menu
			$mainMenuState.change(function () {
				if (this.checked) {
					$("body").addClass("scroll-padlock-locked");
				} else {
					$("body").removeClass("scroll-padlock-locked");
				}
			});
			// hide mobile menu beforeunload
			$(window).on("beforeunload unload", function () {
				if ($mainMenuState[0].checked) {
					$mainMenuState[0].click();
				}
			});
		}
		$(".main-menu__nav").on("click.smapi", function (e) {
			if (e.namespace === "smapi") {
				$("body").css("overflow-y", "auto");
				$mainMenuState[0].click();
			}
		});
	});

	$(".change_mood__open-mobile").on("click.smapi", function (e) {
		$("#main-menu__state").click();
		$("body").addClass("scroll-padlock-locked");
	});
	$(".change_mood__open-desktop").on("click.smapi", function (e) {
		$("#main-menu__state").click();
		$("body").addClass("scroll-padlock-locked");
	});

	/*####### CHANGE MOOD MODAL #######*/
	function modalClose() {
		if (location.hash == "#change_mood_modal") {
			location.hash = "";
		}
		$("body").removeClass("scroll-padlock-locked");
	}
	if ($("#change_mood_modal").length) {
		// Handle ESC key (key code 27)
		document.addEventListener("keyup", function (e) {
			if (e.keyCode == 27) {
				modalClose();
			}
		});

		/*$(".theme-switch").click(function() {
			modalClose();
		});*/

		var modal = document.querySelector("#change_mood_modal");

		// Handle click on the modal container
		modal.addEventListener("click", modalClose, false);

		// Prevent event bubbling if click occurred within modal content body
		modal.children[0].addEventListener(
			"click",
			function (e) {
				e.stopPropagation();
			},
			false
		);

		// Disable scrollbars when modal is open
		$(".change_mood a").click(function (e) {
			$("body").addClass("scroll-padlock-locked");
		});
		$(".position a").click(function (e) {
			$("body").addClass("scroll-padlock-locked");
		});
		// Enable scrollbars when modal is open
		var modalCloseBtn = document.querySelector("#change_mood__close");
		modalCloseBtn.addEventListener("click", modalClose, false);
	}

	/*####### SHRINK HEADER #######*/
	const sentinel = document.getElementById("header-listener");
	const header = document.getElementById("header");

	const options = {
		root: document,
		rootMargin: "0px",
		threshold: 0,
	};

	const callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				header.classList.remove("header--small");
			} else {
				header.classList.add("header--small");
			}
		});
	};
	const observer = new IntersectionObserver(callback, options);
	observer.observe(sentinel);

	/*####### FINISH HOVER ANIMATION #######*/
	$(
		".animation-hover_bounce, .animation-hover_bounce_vert, .animation-hover_bounce_vert_neg"
	).hover(function () {
		$(this).addClass("animated");
	});
	$(
		".animation-hover_bounce, .animation-hover_bounce_vert, .animation-hover_bounce_vert_neg"
	).bind("webkitAnimationEnd mozAnimationEnd animationend", function () {
		$(this).removeClass("animated");
	});
}); /* end of as page load scripts */
